import React from "react"
import Logo from "../images/whiteLogo.svg"
import HeaderStyles from "../styles/themes/SensorMonitor/Header.module.css"
import FooterStyles from "../styles/themes/SensorMonitor/Footer.module.css"
import packageJson from "../../package.json"
import {Link} from "react-router-dom"

export default class SensorMonitor {
  static init() {
    import('../styles/themes/SensorMonitor/general.css').then((_condition) => {});
  }

  static Header({children}) {
    return (
      <div className={HeaderStyles.header}>
        <img src={Logo} alt="SensorMonitor" className={HeaderStyles.logo}/>
        <span className={HeaderStyles.title}>SensorMonitor</span>
        {children}
      </div>
    )
  }

  static Footer() {
    return (
      <div className={FooterStyles.container}>
        v{packageJson.version} - &copy; {new Date().getFullYear()} SensorMonitor
        &nbsp;|&nbsp;
        <Link to="/tietosuojaseloste" target="_blank">Tietosuojaseloste</Link>
      </div>
    )
  }
}
