import mapIconSnowDepthSensor from '../images/mapIconSnowDepthSensor.png'
import mapIconUllageSensor from '../images/mapIconUllageSensor.png'
import mapIconGroup from '../images/mapIconGroup.png'
import mapIconTempSensor from '../images/mapIconTempSensor.png'
import mapIconCo2Sensor from '../images/mapIconCo2Sensor.png'
import mapIconHumiditySensor from '../images/mapIconHumiditySensor.png'
import mapIconMotionSensor from  '../images/mapIconMotionSensor.png'
import mapIconTracker from '../images/mapIconTracker.png'
import mapIconPressureSensor from '../images/mapIconPressureSensor.png'
import mapIconIlluminanceSensor from '../images/mapIconIlluminanceSensor.png'
import mapIconSoundLevelSensor from '../images/mapIconSoundLevelSensor.png'
import mapIconPeopleCounter from '../images/mapIconPeopleCounter.png'
import mapIconVocSensor from '../images/mapIconVocSensor.png'
import mapIconPm25Sensor from '../images/mapIconPm25Sensor.png'
import mapIconPm10Sensor from '../images/mapIconPm10Sensor.png'
import mapIconWaterLeakSensor from '../images/mapIconWaterLeakSensor.png'
import mapIconTimeTracker from '../images/mapIconTimeTracker.png'
import mapIconGateSensor from '../images/mapIconGateSensor.png'
import mapIconSwitch from '../images/mapIconSwitch.png'
import mapIconClimaGuard from '../images/mapIconClimaGuard.png'
import mapIconPowerMeter from '../images/mapIconPowerMeter.png'
import mapIconFireAlarm from '../images/mapIconFireAlarm.png'

export default {
  snow_depth_sensor: mapIconSnowDepthSensor,
  ullage_sensor: mapIconUllageSensor,
  group: mapIconGroup,
  temp_sensor: mapIconTempSensor,
  carbon_dioxide_sensor: mapIconCo2Sensor,
  humidity_sensor: mapIconHumiditySensor,
  motion_sensor: mapIconMotionSensor,
  tracker: mapIconTracker,
  pressure_sensor: mapIconPressureSensor,
  illuminance_sensor: mapIconIlluminanceSensor,
  sound_level_sensor: mapIconSoundLevelSensor,
  people_counter: mapIconPeopleCounter,
  voc_sensor: mapIconVocSensor,
  pm25_sensor: mapIconPm25Sensor,
  pm10_sensor: mapIconPm10Sensor,
  water_leak_sensor: mapIconWaterLeakSensor,
  time_tracker: mapIconTimeTracker,
  gate_sensor: mapIconGateSensor,
  switch: mapIconSwitch,
  clima_guard: mapIconClimaGuard,
  power_meter: mapIconPowerMeter,
  power_consumption_meter: mapIconPowerMeter,
  fire_alarm: mapIconFireAlarm
}
