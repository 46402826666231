import React from 'react'
import { Route, Switch } from "react-router-dom"
import AuthenticatedRoute from "./AuthenticatedRoute"
import LoginForm from '../routes/SignInForm'
import UserIndex from '../routes/UserIndex'
import OrganizationIndex from "../routes/OrganizationIndex"
import OrganizationDetails from "../routes/OrganizationDetails"
import SensorIndex from "../routes/SensorIndex"
import SensorDataIndex from "../routes/SensorDataIndex"
import InstrumentDetails from "../routes/InstrumentDetails"
import InstrumentMonitor from "../routes/InstrumentMonitor"
import styles from '../styles/MainContent.module.css'
import AlertMessage from "./AlertMessage"
import ForgotPasswordForm from "../routes/ForgotPasswordForm"
import SetPasswordForm from "../routes/SetPasswordForm"
import DeviceIndex from "../routes/DeviceIndex"
import PrivacyPolicyEN from "../routes/PrivacyPolicy.en"
import PrivacyPolicyFI from "../routes/PrivacyPolicy.fi"
import DownlinkPresetIndex from "../routes/DownlinkPresetIndex"
import DeviceDetails from "../routes/DeviceDetails"
import OrganizationsDeviceActivityReport from "../routes/OrganizationsDeviceActivityReport"
import DeviceConfigIndex from "../routes/DeviceConfigIndex"

export default () => {
  return (
    <div className={styles.content}>
      <AlertMessage/>
      <Switch>
        <Route path="/privacy-policy">
          <PrivacyPolicyEN/>
        </Route>
        <Route path="/tietosuojaseloste">
          <PrivacyPolicyFI/>
        </Route>
        <AuthenticatedRoute path="/organizations/:organizationId">
          <OrganizationDetails/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/organizations">
          <OrganizationIndex/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/users">
          <UserIndex/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/downlink_presets">
          <DownlinkPresetIndex/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/instruments/:instrumentId">
          <InstrumentDetails/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/sensors/:sensorId/data">
          <SensorDataIndex/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/sensors">
          <SensorIndex/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/devices/:deviceId">
          <DeviceDetails/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/devices">
          <DeviceIndex/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/device_activity_report">
          <OrganizationsDeviceActivityReport/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/device_configs">
          <DeviceConfigIndex/>
        </AuthenticatedRoute>
        <Route path="/login">
          <LoginForm/>
        </Route>
        <Route path="/forgot_password">
          <ForgotPasswordForm/>
        </Route>
        <Route path="/auth/password/edit">
          <SetPasswordForm/>
        </Route>
        <AuthenticatedRoute path="/map">
          <InstrumentMonitor view="map"/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/dashboard">
          <InstrumentMonitor view="dashboard"/>
        </AuthenticatedRoute>
        <Route path="/">
          <LoginForm/>
        </Route>
      </Switch>
    </div>
  )
}