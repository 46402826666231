const protocol = window.location.protocol || 'http:'
const apiHost = process.env.NODE_ENV === 'production' ? `api.sensormonitor.fi` : `localhost`
const apiUrl = process.env.NODE_ENV === 'production' ? `${protocol}//${apiHost}` : `${protocol}//${apiHost}:3000`
const webSocketUrl = process.env.NODE_ENV === 'production' ? `wss://${apiHost}/ws` : `ws://${apiHost}:3666`
//const apiUrl = 'https://api.sensormonitor.fi'
//const webSocketUrl = 'wss://api.sensormonitor.fi/ws'

export default {
  apiUrl: apiUrl,
  jsonApiUrl: apiUrl + '/api/v1/',
  webSocketUrl: webSocketUrl,
  maxDataBatchSize: 9000,
  googleMapsApiKey: 'AIzaSyCY84woBf53UPtRe1vzfG8JlQPZrnvTF6E',
  parameterColors: {
    water_leak: "#5070cd",
    snow_depth: "#5c8ea7",
    level: "#a7854c",
    total_level: "#a7854c",
    product_thickness: "#994ca7",
    humidity: "#5D6AA7",
    co2: "#6CB46A",
    temp: "#FF5945",
    alarm_state: "#FF5945",
    motion: "#745da7",
    pressure: "#ff4f8b",
    illuminance: "#b69500",
    slow_a: '#5D6AA7',
    fast_a: '#6CB46A',
    leq_a: '#857b76',
    slow_c: '#ff4f8b',
    fast_c: '#b69500',
    leq_c: '#FF5945',
    count_in: '#6CB46A',
    count_out: '#5D6AA7',
    count_total: '#cc611e',
    tvoc: '#92b46a',
    pm25: '#acb46a',
    pm10: '#b4aa6a',
    status: '#cc611e',
    gate_state: '#6c849a',
    active_time: '#FF5945',
    power: '#b69500',
    switch_state: '#857b76',
    temp_in: '#FF5945',
    temp_out: '#5070cd',
    temp_min_in: '#00aa00',
    power_consumption: '#b69500'
  }
}