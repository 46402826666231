import Base from './Base'
import uncamelize from "../lib/uncamelize"
import icons from './InstrumentIcons'
import InstrumentDatum from "./InstrumentDatum"
import Time from '../lib/Time'
import config from "../config"
import hexToRGBA from "../lib/hexToRGBA"

class Instrument extends Base {
  static className = 'Instrument'
  static define() {
    this.belongsTo('organization')
    this.belongsTo('instrumentAlarm')
    this.belongsTo('instrumentLatestData')
    this.belongsTo('parent', { className: 'Instrument' })
    this.hasMany('availableSensors', { className: 'Sensor' })
    this.hasMany('instrumentCalibrations')
    this.hasMany('userGroups')
    this.hasMany('tags')
    this.hasMany('children', { className: 'Instrument' })
    this.hasMany('siblings', { className: 'Instrument' })
    this.attributes(
      'name',
      'shortName',
      'fullName',
      'group',
      'ancestors',
      'dataResolution',
      'instrumentType',
      'requiredAttributes',
      'requiredSensors',
      'optionalSensors',
      'dataAttributes',
      'excludedDataAttributes',
      'uiSettings',
      'useParentUserGroups',
      'priority',
      'public',
      'backgroundImage',
      'backgroundImageFilename',
      'takeOfflineAt',
      'takeOnlineAt',
      'remark',
      'description',
      'disabledAt',
      'disabled')
  }

  static dataResolutions = {
    maximum: 'Maximum',
    hourly_avg: 'Hourly average',
    daily_avg: 'Daily average'
  }

  static instrumentTypes = [
    'snow_depth_sensor',
    'ullage_sensor',
    'temp_sensor',
    'humidity_sensor',
    'carbon_dioxide_sensor',
    'motion_sensor',
    'pressure_sensor',
    'pressure_abs_sensor',
    'illuminance_sensor',
    'sound_level_sensor',
    'people_counter_bidirectional',
    'level_probe',
    'voc_sensor',
    'pm25_sensor',
    'pm10_sensor',
    'water_leak_sensor',
    'time_tracker',
    'gate_sensor',
    'tracker',
    'switch',
    'clima_guard',
    'power_meter',
    'power_consumption_meter',
    'fire_alarm'
  ]

  static typeSpecific = {
    clima_guard: {
      primaryAttributes: ['temp_in']
    },
    temp_sensor: {
      name: 'Temperature sensor'
    },
    carbon_dioxide_sensor: {
      name: 'CO2 sensor'
    },
    pressure_sensor: {
      name: 'Pressure sensor (diff)'
    },
    pressure_abs_sensor: {
      name: 'Pressure sensor (abs)',
      icon: 'pressure_sensor'
    },
    sound_level_sensor: {
      primaryAttributes: ['leq_a']
    },
    people_counter_bidirectional: {
      name: 'People counter (bidirectional)',
      primaryAttributes: ['count_in'],
      icon: 'people_counter'
    },
    level_probe: {
      primaryAttributes: ['product_thickness'],
      icon: 'ullage_sensor'
    },
    voc_sensor: {
      name: 'VOC sensor'
    },
    pm25_sensor: {
      name: 'PM2.5 sensor'
    },
    pm10_sensor: {
      name: 'PM10 sensor'
    },
    gate_sensor: {
      name: 'Gate sensor'
    },
    fire_alarm: {
      name: 'Fire alarm'
    },
    tracker: {
      dataAttributes: null
    }
  }

  // For instrument data modal
  static color(attribute){
    if (attribute.substr(-4, 4) === '_min' || attribute.substr(-4, 4) === '_max') {
      const color = config.parameterColors[attribute.substr(0, attribute.length - 4)]
      return hexToRGBA(color, 0.5)
    }
    return config.parameterColors[attribute]
  }

  static formattedValue(value, unit, param){
    let v = ''
    if (param === 'alarm_state') {
      switch (value) {
        case 4:
          v = 'Fire!'
          break
        case 3:
          v = 'Malfunction'
          break
        case 2:
          v = 'Low battery'
          break
        case 1:
          v = 'Test'
          break
        case 0:
          v = 'Ok'
          break
      }
    } else if (param === 'gate_state') {
      v = value ? 'Closed' : 'Open'
    } else {
      v = unit === 'bit' ? (value ? 'On' : 'Off') : value
    }
    return {
      value: v,
      unit: !['n', 'bit'].includes(unit) ? unit : ''
    }
  }

  static valuesForDisplay(instrumentType, values){
    let ret = []
    const primaries = Instrument.typeSpecific[instrumentType] &&
      Instrument.typeSpecific[instrumentType].primaryAttributes
    if (primaries){
      primaries.forEach(pr => {
        const v = values.find(v => v.key === pr)
        if (v !== null)
          ret.push(this.formattedValue(v.value, v.unit, v.key))
      })
    } else {
      values.forEach(v => {
        ret.push(this.formattedValue(v.value, v.unit, v.key))
      })
    }
    return ret
  }

  valueLabels(){
    let ret = [{value: this.name, unit: ''}]
    if (this.latestDataIsTooOld())
      return ret
    if (this.group &&
      this.instrumentLatestData() &&
      this.instrumentLatestData().priorities &&
      this.instrumentLatestData().priorities.length > 0) {
        this.instrumentLatestData().priorities.map(p =>
          ret = ret.concat(Instrument.valuesForDisplay(p.instrumentType, p.values))
        )
    } else if (this.instrumentLatestData() && this.instrumentLatestData().values) {
      ret = ret.concat(Instrument.valuesForDisplay(this.instrumentType, this.instrumentLatestData().values))
    }
    if (this.isTracker() && this.batteryLevel() !== null)
      ret.push({value: this.batteryLevel(), unit: '%'})
    else if (this.isTracker() && this.batteryVoltage() !== null)
      ret.push({value: this.batteryVoltage(), unit: 'V'})
    return ret
  }

  static typeLabel(instrumentType){
    if (Instrument.typeSpecific[instrumentType] && Instrument.typeSpecific[instrumentType].name)
      return Instrument.typeSpecific[instrumentType].name
    else
      return instrumentType.charAt(0).toUpperCase() + instrumentType.slice(1).replace(/_/gi, ' ')
  }

  typeLabel(){
    if (this.group)
      return '- group -'
    else
      return Instrument.typeLabel(this.instrumentType)
  }

  icon(){
    if (this.group)
      return icons.group
    else if (Instrument.typeSpecific[this.instrumentType] && Instrument.typeSpecific[this.instrumentType].icon)
      return icons[Instrument.typeSpecific[this.instrumentType].icon]
    else
      return icons[this.instrumentType]
  }

  validRangeLabel(attribute) {
    const info = this.offRangeInfo(attribute)
    if (!info)
      return 'n/a'
    const range = {min: info.min, max: info.max}
    if (range.min !== undefined && range.min !== null &&
        range.max !== undefined && range.max !== null &&
        range.max !== '' && range.min !== '')
      return `${range.min}..${range.max}`
    else if (range.min !== undefined && range.min !== null && range.min !== '')
      return `≥ ${range.min}`
    else if (range.max !== undefined && range.max !== null && range.max !== '')
      return `≤ ${range.max}`
    else
      return "n/a"
  }

  offRangeInfo(attribute){
    if (!attribute || !this.instrumentLatestData || !this.instrumentLatestData() || !this.instrumentLatestData().offRangeAttributes)
      return null
    const attr = this.instrumentLatestData().offRangeAttributes.find(attr => attr.attribute === uncamelize(attribute, false, '_'))
    if (!attr)
      return null
    return attr
  }

  rangeAlarmIsTriggered(attribute = null){
    if (!this.instrumentLatestData() || !this.instrumentLatestData().offRangeAttributes)
      return false
    if (attribute && this.offRangeInfo(attribute) !== null)
      return true
    else
      return this.instrumentLatestData().offRangeAttributes.length > 0
  }

  dataAgeAlarmIsTriggered(){
    if (!this.instrumentLatestData || !this.instrumentLatestData())
      return false
    return this.instrumentLatestData().hasExpired
  }

  alarmIsTriggered(){
    return this.rangeAlarmIsTriggered() || this.dataAgeAlarmIsTriggered()
  }

  latestCalibration(){
    if (!this.instrumentCalibrations || !this.instrumentCalibrations() || this.instrumentCalibrations().empty())
      return null
    return this.instrumentCalibrations().toArray().sort((a, b) => Date.parse(b.validFrom) - Date.parse(a.validFrom))[0]
  }

  latestLocation(){
    if (!this.instrumentLatestData || !this.instrumentLatestData() || !this.instrumentLatestData().lon || !this.instrumentLatestData().lat)
      return null
    else
      return {lat: this.instrumentLatestData().lat, lon: this.instrumentLatestData().lon}
  }

  rootName(){
    if (this.ancestors.length > 0)
      return this.ancestors[0].name
    else
      return this.name
  }

  batteryLow(){
    return this.batteryLevel() !== null && this.batteryLevel() < 25
  }

  poorSignal(){
    return this.spreadingFactor() !== null && this.spreadingFactor() >= 12
  }

  batteryLevel(){
    if (!this.instrumentLatestData || !this.instrumentLatestData())
      return null
    return this.instrumentLatestData().batteryLevel
  }

  batteryVoltage(){
    if (!this.instrumentLatestData || !this.instrumentLatestData())
      return null
    return this.instrumentLatestData().batteryVoltage
  }

  spreadingFactor(){
    if (!this.instrumentLatestData || !this.instrumentLatestData())
      return null
    return this.instrumentLatestData().spreadingFactor
  }

  spreadingFactorToHuman(){
    switch(this.spreadingFactor()){
      case 12:
        return 'bad signal'
      case 11:
        return 'weak signal'
      case 10:
        return 'okay signal'
      case 9:
        return 'good signal'
      case 8:
        return 'very good signal'
      case 7:
        return 'excellent signal'
      default:
        return ''
    }
  }

  latestData(onlyPrimary = false){
    if (!this.instrumentLatestData || !this.instrumentLatestData() || !this.instrumentLatestData().values)
      return []
    let keys = this.instrumentLatestData().values.map(v => v.key)
    if (onlyPrimary && Instrument.typeSpecific[this.instrumentType] &&
      Instrument.typeSpecific[this.instrumentType].primaryAttributes){
      keys = keys.filter(k =>
        Instrument.typeSpecific[this.instrumentType].primaryAttributes.indexOf(k) !== -1
      )
    }
    return keys.map(key => {
      const datum = this.instrumentLatestData().values.find(v => v.key === key)
      const value = datum !== null ? Instrument.formattedValue(datum.value, datum.unit, key).value : ''
      const unit = datum !== null ? Instrument.formattedValue(datum.value, datum.unit, key).unit : ''
      const label = InstrumentDatum.resolveLabel(key)
      return { key, value, label, unit }
    })
  }

  latestDataIsTooOld(){
    if (!this.instrumentLatestData ||
      !this.instrumentLatestData() ||
      !this.instrumentLatestData().outdatedAt)
      return false
    return Date.now() > Date.parse(this.instrumentLatestData().outdatedAt)
  }

  isTracker(){
    return this.instrumentType === 'tracker'
  }

  titleInfo(){
    const latestData = this.instrumentLatestData && this.instrumentLatestData()
    if (!latestData)
      return ''
    let title = `${this.fullName}\n`
    if (this.group){
      title += `Instruments: ${this.descendantCount}\n`
      latestData.priorities && latestData.priorities.forEach(p => {
        p.values.forEach(v => title += `${p.name} - ${v.key}: ${v.value}${v.unit}\n`)
      })
    } else{
      latestData.values.forEach(v => title += `${v.key}: ${v.value}${v.unit}\n`)
    }
    if (latestData.avgIntervalSec)
      title += `Avg data interval: ${Time.formatDuration(latestData.avgIntervalSec * 1000)}\n`
    title += `Last data at: ${Time.formatTime(latestData.time)} (${Time.formatDuration(latestData.age())} ago)\n`
    if (this.batteryLevel() !== null)
      title += `Battery level: ${this.batteryLevel()}%\n`
    if (this.batteryVoltage() !== null)
      title += `Battery voltage: ${this.batteryVoltage()}V\n`
    if (this.spreadingFactor() !== null)
      title += `Spreading factor: ${this.spreadingFactor()} (${this.spreadingFactorToHuman()})`
    return title
  }

  isOffline(){
    const latestData = this.instrumentLatestData && this.instrumentLatestData()
    if (!latestData)
      return false
    return latestData.takenOfflineAt && Date.parse(latestData.takenOfflineAt) < Date.now()
  }

  hasBackground(){
    return this.backgroundImageFilename && this.backgroundImageFilename !== ''
  }
}

export default Base.createResource(Instrument)